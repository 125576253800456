import React, { Component } from "react"
import { Radio } from "semantic-ui-react"
import {
  sendEmail,
  validEmailRegex,
  validateForm,
} from "../../functions/formFunctions"
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google"

class GeneralForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      term: "",
      content: "",
      gdpr: "yes",
      errors: {
        email: "",
      },
    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  closeForm = () => {
    console.log(this.props)
    window.location.replace(
      this.props.returnLink.length ? this.props.returnLink : "/"
    )
  }

  handleChange = (e, data) => {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors

    if (data) {
      name = data.name
      value = data.checked

      if (name === "gdpr") {
        value = data.value
      }
    }

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "→ Error: Email address is not valid."
        break
      default:
        break
    }

    this.setState({
      errors: errors,
      [name]: value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { email } = this.state
    let errors = this.state.errors

    if (!email) {
      errors.email = "→ Error: valid email address required"
    }

    this.setState({
      errors: errors,
      sending: true,
    })

    if (validateForm(errors)) {
      this.captcha.execute()
    } else {
      this.setState({
        sending: false,
      })
    }
  }

  componentDidMount() {
    loadReCaptcha()
    if (this.captcha) {
      this.captcha.reset()
    }
  }

  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset()
    }
  }

  verifyCallback(recaptchaToken) {
    if (recaptchaToken) {
      sendEmail(this.props.type, this.props.returnLink, this.state)
    }
  }

  render() {
    return (
      <>
        <h4 className="heading--s caps bold sans">{this.props.title}</h4>
        {!this.props.sent ? (
          <>
            <p
              className="body--l"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
            <form
              className="submit_form"
              name="contact"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />

              {this.props.type === "term" && (
                <div className="form-input">
                  <label>Term</label>
                  <input
                    type="text"
                    name="content"
                    onChange={this.handleChange}
                  />
                </div>
              )}

              {this.props.type === "question" && (
                <div className="form-input">
                  <label>Question</label>
                  <textarea name="content" onChange={this.handleChange} />
                </div>
              )}

              {this.props.type === "general" && (
                <div className="form-input">
                  <label>Note</label>
                  <textarea name="content" onChange={this.handleChange} />
                </div>
              )}

              <div className="form-input">
                <div className="textarea_label">
                  <span className="label" htmlFor="name">
                    Name
                  </span>
                  <span className="label_span">Optional</span>
                </div>
                <input type="text" name="name" onChange={this.handleChange} />
              </div>
              <div className="form-input">
                <div className="textarea_label">
                  <span className="label" htmlFor="email">
                    Email
                  </span>
                  <span className="label_span">Optional</span>
                </div>

                <input type="email" name="email" onChange={this.handleChange} />
              </div>
              <div className="form-input form-radio">
                <h4 className="heading--xs bold sans">
                  Want to hear more about this campaign?
                </h4>
                <p className="body--s sans">
                  Choose ‘Yes’ to get emails from Orthopaedic Research UK about
                  the Own Pain campaign, as well as other news and fundraising
                  updates by email. You can unsubscribe at any time.
                </p>

                <Radio
                  label="Yes"
                  name="gdpr"
                  value="yes"
                  checked={this.state.gdpr === "yes"}
                  onChange={this.handleChange}
                />

                <Radio
                  label="No"
                  name="gdpr"
                  value="no"
                  checked={this.state.gdpr === "no"}
                  onChange={this.handleChange}
                />
              </div>
              <ReCaptcha
                ref={el => {
                  this.captcha = el
                }}
                size="invisible"
                data-theme="dark"
                render="explicit"
                sitekey="6Lf7b6UZAAAAAJ_7xMhdhY9ZLMANLXKcejMh6XBo"
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
              />
              <button type="submit" className="btn green large">
                <span>Send</span>
              </button>
            </form>
          </>
        ) : (
          <>
            <p className="body--m">
              Thank you, we have received your message. We will review this and
              be in touch where necessary.
            </p>
            <button className="btn green" onClick={this.closeForm}>
              <span>Close</span>
            </button>
          </>
        )}
      </>
    )
  }
}

export default GeneralForm
