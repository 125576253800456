import React, { Component } from "react"
import lunr from "lunr"
import { Link } from "gatsby"

// https://stackoverflow.com/a/24006120/2255980
// this function will convert the absolute paths that GraphQL gives us
// into relative paths, which is what Gatsby Link needs.
// so for example
// this https://localhost:8000/a-post-about-bagels
// becomes /a-post-about-bagels
function localiseUrl(url) {
  try {
    const newURL = new URL(url)
    return newURL.pathname
  } catch {
    return url
  }
}

// the user must type this many characters before a search will happen
const minCharacters = 3
// we trim the results text to this value, else we dont know how much content
// we're rendering onto the page
const contentCharLimit = 200

// this builds Lunr's index. It's a necessary step to using Lunr.
// With enormous sites this could become slow, so a future task might be to
// pre-build the index when Gatsby builds (probably in gatsby-node.js)
// https://lunrjs.com/guides/index_prebuilding.html
function getIndex(posts) {
  return lunr(function () {
    this.pipeline.remove(lunr.stemmer)
    this.ref("id")
    this.field("title")
    this.field("body")

    posts.map(post => {
      return this.add({
        id: post.id,
        title: post.post_title,
        body: post.searchData.join("\n\n"),
      })
    })
  })
}

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: "",
      idx: null,
      results: [],
    }
  }

  componentDidMount() {
    const search_term = this.props.search_term

    this.setState({
      idx: getIndex(this.props.data),
      query: search_term,
    })

    if (search_term) {
      this.runQuery(search_term)
    }
  }

  onChange = e => {
    let query = e.target.value

    this.setState({
      results: [],
      query: query,
      searched: false,
    })
  }

  onSubmit = e => {
    e.preventDefault()
    let query = this.state.query

    this.runQuery(query)
  }

  runQuery = query => {
    this.setState({
      searched: true,
    })

    const keywords = query
      .trim() // remove trailing and leading spaces
      .replace(/\*/g, "") // remove user's wildcards
      .toLowerCase()
      .split(/\s+/)

    try {
      // andSearch stores the intersection of all per-term results
      let andSearch = []
      const index = getIndex(this.props.data)

      keywords
        .filter(el => el.length > 1)
        // loop over keywords
        .forEach((el, i) => {
          // per-single-keyword results
          const keywordSearch = index
            .query(function (q) {
              q.term(el, { editDistance: 0 })
              q.term(el, {
                wildcard:
                  lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
              })
            })
            .map(({ ref }) => {
              return {
                slug: ref,
              }
            })

          andSearch =
            i > 0
              ? andSearch.filter(x =>
                  keywordSearch.some(el => el.slug === x.slug)
                )
              : keywordSearch
        })

      let newResults = []

      for (let e in andSearch) {
        newResults.push(andSearch[e].slug)
      }

      this.setState({
        results: newResults,
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col col-9">
            <div className="search_form">
              <div className="search_form_input">
                <form onSubmit={this.onSubmit.bind(this)}>
                  <input
                    onChange={this.onChange.bind(this)}
                    value={this.state.query}
                    placeholder="Search..."
                    type="text"
                  />
                </form>
              </div>

              {!!this.state.results.length &&
                this.state.query.length >= minCharacters && (
                  <p className="result-count">
                    Showing <span>{this.state.results.length}</span> results for
                    <span> {this.state.query}</span>
                  </p>
                )}

              {!this.state.searched && (
                <p className="result-count">Press enter to start searching</p>
              )}
            </div>
          </div>
        </div>

        {this.state.results && (
          <ul className="search-results">
            <li>
              <div className="row">
                <div className="col col-3">
                  <p className="heading--xs caps sans bold">Path</p>
                </div>
                <div className="col col-6">
                  <p className="heading--xs caps sans bold">content</p>
                </div>
              </div>
            </li>

            {!this.state.results.length && this.state.searched && (
              <li className="search-result no-results">
                <h5 className="heading--l bold">
                  No results found for{" "}
                  <span className="sans">{this.state.query}</span>
                </h5>
              </li>
            )}

            {this.state.results.length > 0 &&
              this.state.results.map(o => {
                // Filter through all of the graphql data provided by the parent component
                // to find the ID which matches the results provided by Lunr
                // and just grab the first and only item (it'll be a single item array)
                const post = this.props.data.filter(post => post.id === o)[0]

                // content can potentially be long. if it exceeds our contentCharLimit
                // we cap it, and append a ...
                const content = post.searchData[0]
                const contentCapped = `${content?.substring(
                  0,
                  contentCharLimit
                )}${content?.length > contentCharLimit ? "..." : ""}`

                let pathName =
                  "Home " + post.pathname.replace("/", "<span>/</span>")

                if (post.post_title === "Home") {
                  post.pathname = "/"
                  pathName = "/"
                }

                return (
                  <li className="search-result" key={post.id}>
                    <div className="row">
                      <div className="col col-3">
                        <div
                          className="breadcrumb body--s sans"
                          dangerouslySetInnerHTML={{
                            __html: post.breadcrumb,
                          }}
                        />
                      </div>
                      <div className="col col-6">
                        <Link
                          to={localiseUrl(post.pathname) + "#highlight"}
                          state={{ query: this.state.query }}
                        >
                          <h2 className="heading--l">{post.post_title}</h2>
                        </Link>
                        {content && (
                          <div
                            className="search-result__content"
                            dangerouslySetInnerHTML={{
                              __html: contentCapped,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        )}
      </>
    )
  }
}

export default SearchResults
