const mandrill = require("mandrill-api/mandrill")
const mandrill_client = new mandrill.Mandrill("FU8behUlpNca4ECs0wY3Rw")

export const sendEmail = async (type, returnLink, userInfo) => {
  let message
  let confirmationMessage

  if (type === "story") {
    message = {
      subject: "Thanks for sharing your story",
      from_email: "ownpain@oruk.org",
      from_name: "ORUK - Own Pain",
      to: [
        {
          email: userInfo.email,
          type: "to",
        },
      ],
      merge_language: "handlebars",
      headers: {
        "Reply-To": "ownpain@oruk.org",
      },
      html:
        userInfo.name +
        "<p>Thank you so much for sharing your story of living with bone, joint or muscle pain.</p>" +
        "<p>We hope that by sharing real life experiences like yours, we can raise awareness of chronic pain and show people they're not alone.</p>" +
        "<p>Your story may be used on our website, social media, emails and in other ways as part of Orthopaedic Research UK's Own Pain campaign. </p>" +
        "<p>By offering support, resources and a sense of community, the campaign aims to help people find ways to reduce their pain and live more active, healthier lives.</p>" +
        "<p>If you have any questions, or would like to talk about how your story will be used, you can contact us on <email> or call <phone number>.</p>" +
        "<p>Thanks again and best wishes,</p>" +
        "<p></p>" +
        "<p>Orthopaedic Research UK</p>",
    }
    confirmationMessage = {
      subject: "New submission",
      from_email: "ownpain@oruk.org",
      from_name: "ORUK - Own Pain",
      to: [
        {
          email: "ownpain@oruk.org",
          type: "to",
        },
      ],
      merge_language: "handlebars",
      headers: {
        "Reply-To": "ownpain@oruk.org",
      },
      html:
        "<p>Name: " +
        userInfo.name +
        "</p>" +
        "<p>Email: " +
        userInfo.email +
        "</p>" +
        "<p>Story: " +
        userInfo.story +
        "</p>" +
        "<p>Upload: " +
        userInfo.video +
        "</p>" +
        "<p>Agree to be contacted: " +
        userInfo.gdpr +
        "</p>",
    }
  } else {
    message = {
      subject: "New message from ownpain.co.uk (" + type + ")",
      from_email: "ownpain@oruk.org",
      from_name: "ORUK - Own Pain",
      to: [
        {
          email: "ownpain@oruk.org",
          type: "to",
        },
      ],
      merge_language: "handlebars",
      headers: {
        "Reply-To": "ownpain@oruk.org",
      },
      html:
        "<p>Name: " +
        userInfo.name +
        "</p>" +
        "<p>Email: " +
        userInfo.email +
        "</p>" +
        "<p>Content: " +
        userInfo.content +
        "</p>" +
        "<p>Agree to be contacted: " +
        userInfo.gdpr +
        "</p>",
    }
  }

  return new Promise((resolve, reject) => {
    mandrill_client.messages.send({ message: message }, function (result) {
      if (type === "story") {
        // now send confirmation email
        mandrill_client.messages.send(
          { message: confirmationMessage },
          function (result) {
            window.location.replace(`/thankyou`)
          }
        )
      } else {
        window.location.replace(returnLink + "?submitted=true")
      }
    })
  })
}

// eslint-disable-next-line
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
)

export const validateForm = errors => {
  let valid = true
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    val => val.length > 0 && (valid = false)
  )
  return valid
}
