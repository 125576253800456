import React from "react"
import { Link } from "gatsby"
import SearchInfo from "../components/search/searchInfo"
import Layout from "../components/layout"
import { useQueryParam, StringParam } from "use-query-params"

const SearchPage = () => {
  const [search_term] = useQueryParam("term", StringParam)

  return (
    <Layout page="search" seo={{ title: "Search" }}>
      <section className="page_header support_header">
        <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span>/</span>
            <p>Search</p>
          </div>
          <h1 className="heading--xl bold">Search</h1>
          <SearchInfo search_term={search_term} />
        </div>
      </section>
    </Layout>
  )
}

export default SearchPage
