import React from "react"
import { Link } from "gatsby"

const StoryCard = props => {
  return (
    <div className="story">
      <div className="story_inner">
        <div className="story_image">
          <img src={props.image_url} alt="" />
        </div>
        <div className="story_text">
          <h3 className="heading--l sans thin">{props.title}</h3>
          <p className="body--m">{props.description}</p>
          <div className="story_btns">
            <Link
              className={"btn right yellow"}
              to={"own-stories/" + props.slug}
            >
              <span>Read more</span>
            </Link>
            <button className={"btn video right yellow"} to={props.slug}>
              <span>Watch video</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryCard
