import React, { Component } from "react"
import { Link } from "gatsby"
import { ORUK_logo } from "../images"

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="wrapper">
          <div className="footer_inner">
            <div className="row">
              <div className="col col-2">
                <h4 className="heading--xs sans bold">Menu</h4>
                <ul className="footer_links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/support/talking-to-your-gp">Talking to your GP</Link>
                  </li>
                  <li>
                    <Link to="/support/medicines">Medicines</Link>
                  </li>
                  <li>
                    <Link to="/own-stories">Own Stories</Link>
                  </li>
                  <li>
                    <Link to="/about-the-campaign">About the campaign</Link>
                  </li>
                  <li>
                    <Link to="/">About ORUK</Link>
                  </li>
                </ul>
              </div>
              <div className="col col-2">
                <ul className="footer_social">
                  <h4 className="heading--xs sans bold">Social</h4>
                  <li>
                    <a
                      href="https://twitter.com/OR_UK"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/ORUKcharity"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/or_uk/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col col-2">
                <h4 className="heading--xs sans bold">Campaign created by</h4>
                <img src={ORUK_logo} alt="ORUK" width="126px" />
              </div>
              <div className="col col-6">
                <div className="footer_about">
                  <p className="body--xs sans">
                    Orthopaedic Research UK is a UK Registered Charity No.
                    1111657 and a Company registered in England and Limited by
                    Guarantee No. 5585452.
                  </p>
                  <p className="body--xs sans">
                    Registered Office: Rosemount House, Rosemount Avenue, West
                    Byfleet, Surrey KT14 6LB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
export default Footer
