import _ from "lodash"
import React, { Component } from "react"
import { Search } from "semantic-ui-react"

const initialState = { isLoading: false, results: [], value: "" }

class GlossarySearch extends Component {
  wordChange = word => {
    return word.replace(/\s+/g, "").toLowerCase().split("(")[0].split("<")[0]
  }

  componentDidMount() {
    const data = this.props.data
    let terms = []

    for (let i in data) {
      const words = data[i].terms

      for (let e in words) {
        if (words[e].title) {
          let newTerm = {
            title: words[e].title
              .split("<sup>")[0]
              .replace('<span class="highlight">', "")
              .replace("</span>", ""),
          }

          terms.push(newTerm)
        }
      }
    }

    this.setState({
      terms: terms,
    })
  }

  state = initialState

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title })

    result.id = result.title.replace(/\s+/g, "-").toLowerCase()

    const el = document.getElementById("word_" + result.id)
    const sticky = document.getElementById("glossary_sticky")

    const y =
      el.getBoundingClientRect().top + window.pageYOffset - sticky.offsetHeight

    window.scrollTo({ top: y, behavior: "smooth" })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), "i")
      const isMatch = result => re.test(result.title)

      this.setState({
        isLoading: false,
        results: _.filter(this.state.terms, isMatch),
      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <Search
        input={{ icon: "search", iconPosition: "left" }}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={value}
        placeholder="Search for a term"
        {...this.props}
      />
    )
  }
}

export default GlossarySearch
