export function searchTerm(query, string) {
  let patt = new RegExp(query, "gi")

  if (query) {
    return string.replace(patt, function (match) {
      return '<span class="highlight" id="highlight">' + match + "</span>"
    })
  } else {
    return string
  }
}
