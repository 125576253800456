import React from "react"
import { graphql, Link } from "gatsby"
import StoryCard from "../components/story"
import Layout from "../components/layout"
import { searchTerm } from "../functions/searchReplace"

const OwnStories = ({ data, location }) => {
  const title = "Own Stories"
  const content = data.wpPage.content
  const posts = data.allWpStory.edges

  return (
    <Layout seo={{ title: title }} page="own-stories">
      <section className="page_header">
        <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span>/</span>
            <p>{title}</p>
          </div>
          <div className="row">
            <div className="col col-6">
              <div className="page_intro">
                <div
                  className="page_intro"
                  dangerouslySetInnerHTML={{
                    __html: location.state
                      ? searchTerm(location.state.query, content)
                      : content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="own-stories_stories">
        <div className="wrapper">
          <div className="row centered">
            <div className="col col-10 stories">
              {posts.map(({ node }, key) => {
                return (
                  <StoryCard
                    slug={node.slug}
                    image_url={node.stories.photo.sourceUrl}
                    title={node.title}
                    description={node.stories.description}
                    key={key}
                    id={key}
                  />
                )
              })}
            </div>
            <div className="col col-10">
              <Link
                to="/submissions/#submit_your_story"
                className="stories_banner"
              >
                <h3 className="heading--m">
                  Have your own story you’d like to share? <br />
                  We’d love to hear it.
                </h3>
                <div className="stories_banner__link">
                  <div className="illustration_sattablet illustration_01"></div>
                  <p className="body--m sans">Tell us your story</p>
                  <span className="btn_small red">→</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "own-stories" }) {
      id
      title
      content
    }
    allWpStory {
      edges {
        node {
          title
          slug
          stories {
            description
            story
            photo {
              sourceUrl
              altText
            }
          }
        }
      }
    }
  }
`

export default OwnStories
