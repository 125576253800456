import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SearchResults from "./searchResults"

export default function SearchInfo(props) {
  const data = useStaticQuery(graphql`
    {
      allWpPage {
        edges {
          node {
            id
            content
            title
            slug
            uri
            nodeType
            sections {
              section {
                text
              }
            }
            pageGlossary {
              glossaryTerms {
                terms {
                  description
                  title
                }
              }
            }
          }
        }
      }
      allWpStory {
        edges {
          node {
            id
            title
            nodeType
            slug
            uri
            stories {
              description
              story
            }
          }
        }
      }
    }
  `)

  let newData = []

  for (let i in data) {
    let edges = data[i].edges

    for (let e in edges) {
      let searchData = []
      let parent = ""

      if (edges[e].node.uri.includes("own-stories")) {
        parent = "<p>Own Stories</p><span>/</span>"
      }

      if (edges[e].node.uri.includes("support")) {
        parent = "<p>Support</p><span>/</span>"
      }

      let breadcrumb =
        "<p>Home</p><span>/</span>" +
        parent +
        "<p>" +
        edges[e].node.title +
        "</p>"

      if (edges[e].node.uri.includes("home")) {
        breadcrumb = "<p>Home</p>"
      }

      // go through all content fields
      if (edges[e].node.content) {
        searchData.push(edges[e].node.content.replace(/(<([^>]+)>)/gi, ""))
      }

      if (edges[e].node.sections) {
        if (edges[e].node.sections.section) {
          for (let j in edges[e].node.sections.section) {
            searchData.push(
              edges[e].node.sections.section[j].text.replace(
                /(<([^>]+)>)/gi,
                ""
              )
            )
          }
        }
      }

      if (edges[e].node.pageGlossary) {
        if (edges[e].node.pageGlossary.glossaryTerms) {
          for (let a in edges[e].node.pageGlossary.glossaryTerms) {
            let termGroup = edges[e].node.pageGlossary.glossaryTerms[a]

            for (let term in termGroup) {
              for (let u in termGroup[term]) {
                if (termGroup[term][u].description) {
                  searchData.push(
                    termGroup[term][u].description.replace(/(<([^>]+)>)/gi, "")
                  )
                  searchData.push(
                    termGroup[term][u].title.replace(/(<([^>]+)>)/gi, "")
                  )
                }
              }
            }
          }
        }
      }

      // check stories
      if (edges[e].node.stories) {
        searchData.push(
          edges[e].node.stories.description.replace(/(<([^>]+)>)/gi, "")
        )

        if (edges[e].node.stories.story) {
          searchData.push(
            edges[e].node.stories.story.replace(/(<([^>]+)>)/gi, "")
          )
        }
      }

      let postInfo = {
        id: edges[e].node.id,
        pathname: edges[e].node.uri,
        post_title: edges[e].node.title,
        post_type: edges[e].node.nodeType,
        searchData: searchData,
        breadcrumb: breadcrumb,
      }

      newData.push(postInfo)
    }
  }

  return <SearchResults data={newData} search_term={props.search_term} />
}
