import React, { Component } from "react"
import { navigateTo } from "gatsby"
import { searchTerm } from "../functions/searchReplace"
import { Modal } from "semantic-ui-react"
import { About01, About02 } from "../images"
import GeneralForm from "./forms/form"
import scrollTo from "gatsby-plugin-smoothscroll"

class Sections extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      modal: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      sent: false,
    })

    navigateTo(this.props.return)
  }

  handleScroll = event => {
    let navPos = 0

    if (this.myDiv) {
      navPos = this.myDiv.getBoundingClientRect().top

      for (var i in this.list.children) {
        let childElement = this.list.children[i]
        let id = childElement.id

        if (id) {
          let top = childElement.getBoundingClientRect().top
          let bottom = childElement.getBoundingClientRect().bottom

          if (top < this.sticky.offsetHeight && bottom > 0) {
            this.setState({
              active: id,
            })
          }
        }
      }

      if (navPos < 0) {
        this.setState({
          sticky: true,
        })
      } else {
        this.setState({
          sticky: false,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true)

    if (this.props.form_status) {
      this.setState({
        modal: true,
        sent: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <>
        <section
          className={this.state.sticky ? "section_nav sticky" : "section_nav"}
          id="myDiv"
          ref={el => {
            this.myDiv = el
          }}
        >
          <div className="wrapper">
            <h4 className="heading--xs caps sans">contents</h4>
            <div className="section_nav__btns">
              {this.props.sections.map((data, index) => {
                const label = data.label.toLowerCase().replace(/\s/g, "")
                return (
                  <button
                    className={"btn " + this.props.color}
                    key={index}
                    onClick={() => scrollTo("#" + label)}
                  >
                    <span>{data.label}</span>
                  </button>
                )
              })}
            </div>
          </div>
          <div
            className="sticky-bar"
            ref={el => {
              this.sticky = el
            }}
          >
            <div className="wrapper">
              <div className="section_nav__btns">
                {this.props.sections.map((data, index) => {
                  const label = data.label.toLowerCase().replace(/\s/g, "")

                  return (
                    <button
                      className={
                        this.state.active === label
                          ? "btn active " + this.props.color
                          : "btn " + this.props.color
                      }
                      key={index}
                      onClick={() => scrollTo("#" + label)}
                    >
                      <span>{data.label}</span>
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="page_sections">
          <div
            ref={el => {
              this.list = el
            }}
            className={"page_sections__inner " + this.props.page}
          >
            {this.props.sections.map((data, index) => {
              const label = data.label.toLowerCase().replace(/\s/g, "")

              return label === "otherwaystomanagepain" ? (
                <div
                  className="page_section support_pain"
                  key={index}
                  id={label}
                >
                  <div className="wrapper">
                    <div className="support_pain__inner">
                      <div className="row">
                        <div className="col col-1 col-empty"></div>
                        <div className="col col-6">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.location.state
                                ? searchTerm(
                                    this.props.location.state.query,
                                    data.text
                                  )
                                : data.text,
                            }}
                          />
                        </div>
                        <div className="col col-1 col-empty"></div>
                        <div className="col col-3">
                          <div
                            className="footnotes"
                            dangerouslySetInnerHTML={{
                              __html: data.extraField,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="page_section" key={index} id={label}>
                  <div className="wrapper">
                    <div className="row">
                      <div className="col col-1 col-empty col_first"></div>
                      <div className="col col-6 col_text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.location.state
                              ? searchTerm(
                                  this.props.location.state.query,
                                  data.text
                                )
                              : data.text,
                          }}
                        />
                      </div>
                      <div className="col col-1 col-empty col_middle"></div>
                      <div className="col col-3 col_img">
                        {label === "questionstoask" ? (
                          <>
                            <div className="support_download">
                              <div className="support_download__inner">
                                <p className="body--m sans">
                                  <strong>Tip:</strong> download and print this
                                  sheet of questions out to take with you.
                                </p>
                              </div>
                              <button
                                className="support_download__btn btn down darkgreen"
                                href="#"
                                download
                              >
                                <span>Download PDF</span>
                              </button>
                            </div>
                            <div
                              className="support_questions cta_question"
                              onClick={this.toggleModal}
                            >
                              <h3 className="heading--m">
                                Have other questions we haven't included? Let us
                                know.
                              </h3>
                              <span className="btn_small green">→</span>
                            </div>
                          </>
                        ) : (
                          <div
                            className="footnotes"
                            dangerouslySetInnerHTML={{
                              __html: data.extraField,
                            }}
                          />
                        )}
                      </div>
                      <div className="col col-1 col-empty col_last"></div>
                    </div>
                  </div>
                </div>
              )
            })}
            {this.props.page === "about-the-campaign" && (
              <div className="page_section about-row">
                <div className="wrapper">
                  <div className="row">
                    <div className="col col-6">
                      <img src={About01} alt="" />
                    </div>
                    <div className="col col-6">
                      <img src={About02} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        <Modal
          size="tiny"
          onClose={() => this.toggleModal()}
          onOpen={() => this.toggleModal()}
          open={this.state.modal}
        >
          <Modal.Content>
            <GeneralForm
              title="Ask a question"
              description="Have other questions we've not addressed? Let us know here or send an email to <a href='mailto:ownpain@oruk.org.uk'>ownpain@oruk.org.uk</a>."
              type="question"
              returnLink="/support/talking-to-your-gp/"
              sent={this.state.sent}
            />
          </Modal.Content>
        </Modal>
      </>
    )
  }
}

export default Sections
