import React from "react"
import PageLayout from "../components/pageLayout"
import { Link } from "gatsby"

const Thankyou = () => {
  return (
    <PageLayout seo={{ title: "Thank you" }}>
      <h1>Thank you for sharing your story.</h1>
      <p>
        We hope that by sharing real life experiences like yours, we can raise
        awareness of chronic pain and show people they’re not alone.
      </p>
      <p>
        You should receive a confirmation email shortly. If you have any
        questions or want to speak to us about your submission you can get in
        touch with us at info@ownpain.co.uk
      </p>
      <Link to="/" className="btn green right">
        <span>Take me home</span>
      </Link>
    </PageLayout>
  )
}

export default Thankyou
