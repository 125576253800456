import React from "react"
import Layout from "../components/layout"
import { AboutHeader } from "../images"
import Sections from "../components/sections"
import ContactBanner from "../components/banners/contact"
import scrollTo from "gatsby-plugin-smoothscroll"
import { searchTerm } from "../functions/searchReplace"
import { Link, graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"

const About = ({ data, location }) => {
  const [form_status] = useQueryParam("submitted", StringParam)
  const title = data.wpPage.title
  const content = data.wpPage.content
  const sections = data.wpPage.sections.section

  return (
    <Layout seo={{ title: title }} page="about-the-campaign">
      <section className="page_header support_header">
        <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span>/</span>
            <p>{title}</p>
          </div>
          <div className="row">
            <div className="col col-6">
              <div
                className="page_intro"
                dangerouslySetInnerHTML={{
                  __html: location.state
                    ? searchTerm(location.state.query, content)
                    : content,
                }}
              />
            </div>
            <div className="col col-1 col-empty"></div>
            <div className="col col-5 col-image">
              <img src={AboutHeader} alt="" />
            </div>
          </div>
        </div>
      </section>
      <Sections
        color="darkred"
        page="about-the-campaign"
        sections={sections}
        location={location}
      />
      <ContactBanner form_status={form_status} return="/about-the-campaign/" />
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutQuery {
    wpPage(slug: { eq: "about-the-campaign" }) {
      id
      title
      content
      sections {
        section {
          label
          text
          extraField
        }
      }
    }
  }
`
