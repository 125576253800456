import React, { Component } from "react"

class HeaderSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      term: "",
    }
    this.onChange = this.onChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
  }
  onChange = e => {
    this.setState({
      term: e.target.value,
    })
  }
  formSubmit = e => {
    e.preventDefault()
    window.location = "/search/?term=" + this.state.term
  }
  render() {
    return (
      <form
        className={"search_form pos-" + this.props.position}
        onSubmit={this.formSubmit}
      >
        <div className="search_form_input">
          <input
            type="search"
            value={this.state.term}
            onChange={this.onChange}
            placeholder={
              this.props.placeholder ? this.props.placeholder : "Search"
            }
          />
        </div>
        <button>search</button>
      </form>
    )
  }
}

export default HeaderSearch
