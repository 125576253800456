import React, { useEffect } from "react"
import Rellax from "rellax"
import scrollTo from "gatsby-plugin-smoothscroll"
import Login from "../components/login"
import UploadForm from "../components/forms/uploadForm"
import Layout from "../components/layout"
import Footer from "../components/footer"
import {
  Logo,
  Holding01,
  Holding02,
  Holding03,
  Holding04,
  Holding05,
  Holding06,
  Holding07,
  Holding08,
  ORUK_logo,
  ArmLeft,
  ArmRight,
} from "../images"

const Submissions = ({ data, location }) => {
  useEffect(() => {
    new Rellax(".image03", {
      speed: 4,
      percentage: 0,
      center: true,
    })

    new Rellax(".image04", {
      speed: 6,
      percentage: 0,
      center: true,
    })

    new Rellax(".mobile_03", {
      speed: 1,
      percentage: 0,
      center: true,
    })
  }, [])

  return (
    <Layout seo={{ title: "Submissions" }}>
      <Login />
      <div className="page-submissions" id="submit-your-story">
        <section className="section_intro">
          <div className="image05">
            <img src={Holding05} alt="" />
          </div>

          <div className="wrapper">
            <div className="row">
              <div className="col col-2 col-empty"></div>
              <div className="col col-7">
                <div className="intro">
                  <h2 className="heading--xl bold">
                    Do you live with pain in your bones, muscles or joints?
                  </h2>
                  <p className="body--xl">
                    We want to hear about the challenges you face, and how you
                    overcome them. By sharing your story, you can help raise
                    awareness of chronic pain and show others they’re not alone.
                  </p>
                  <button
                    className="btn down yellow"
                    onClick={() => scrollTo("#section_form")}
                  >
                    <span>Tell your story</span>
                  </button>
                  <div className="mobile">
                    <div className="mobile_01">
                      <img src={ArmLeft} alt="" />
                    </div>
                    <div className="mobile_02">
                      <img src={Holding01} alt="" />
                    </div>
                    <div className="mobile_03">
                      <img src={Holding02} alt="" />
                    </div>
                    <div className="mobile_04">
                      <img src={ArmRight} alt="" />
                    </div>
                    <div className="mobile_05">
                      <img src={Holding03} alt="" />
                    </div>
                  </div>
                  <div className="image-holder">
                    <div className="image02">
                      <img src={Holding02} alt="" />
                    </div>
                    <div className="image03">
                      <img src={Holding03} alt="" />
                    </div>
                    <div className="image04">
                      <img src={Holding04} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_form" id="section_form">
          <div className="image06">
            <img src={Holding08} alt="" />
          </div>
          <div className="wrapper">
            <div className="row">
              <div className="col col-2 col-empty"></div>
              <div className="col col-6">
                <div className="text-container">
                  <span className="lozenge yellow">Tell us your story</span>
                  <h2 className="heading--xl bold">How do you own pain?</h2>
                  <p className="body--m">
                    Your story could give hope to people struggling with bone,
                    joint or muscle pain. Just film a short clip of up to two
                    minutes and upload it below. Or use the text box to write
                    about your experience.
                  </p>
                  <div className="callout">
                    <h4 className="heading--s bold sans caps">
                      Here are a few things to think about:
                    </h4>
                    <ul>
                      <li>What kind of pain do you experience?</li>
                      <li>Does it stop you doing physical activities?</li>
                      <li>How does it affect your work and social life?</li>
                      <li>What do you do to reduce or manage your pain?</li>
                      <li>Do you have any advice for coping with pain?</li>
                    </ul>
                  </div>
                  <div className="callout">
                    <h4 className="heading--s bold sans caps">
                      Tips on filming
                    </h4>
                    <ul>
                      <li>Any smartphone will do great.</li>
                      <li>
                        You can hold the phone yourself, or set it on something
                        to hold it up while you speak. Or get someone else to
                        film you!
                      </li>
                      <li>
                        Film horizontally by turning your phone sideways. (It's
                        ok to film vertically if that's more comfortable.)
                      </li>
                      <li>Pick a place with plenty of light on your face.</li>
                      <li>
                        Try and choose a location with minimal background noise
                        where you won't be interrupted.
                      </li>
                    </ul>
                  </div>
                  <p className="body--m">
                    Thanks so much for sharing your story. We’d like to use it
                    on our campaign website and in other ways to help support
                    people in pain. See the{" "}
                    <a href="/terms-of-use" target="_blank" rel="noreferrer">
                      Terms of Use
                    </a>{" "}
                    for full details of how we’d like to use your story.
                  </p>
                </div>
                <UploadForm />
              </div>
            </div>
            <div className="section_intro">
              <div className="intro second">
                <div className="mobile">
                  <div className="mobile_06">
                    <img src={Holding06} alt="" />
                  </div>
                  <div className="mobile_07">
                    <img src={ORUK_logo} alt="" />
                  </div>
                </div>
              </div>

              <div className="row secondary">
                <div className="col col-2 col-empty"></div>
                <div className="col col-10 col-empty">
                  <div className="images">
                    <img src={ORUK_logo} alt="" />
                    <img src={Holding06} alt="" />
                    <img src={Holding07} alt="" />
                  </div>
                </div>
                <div className="col col-2 col-empty"></div>
                <div className="col col-6">
                  <p className="body--m">
                    At Orthopaedic Research UK, we know how tough it can be
                    living with bone, joint and muscle conditions. The pain can
                    stop you doing things you love, and make it harder to work,
                    see friends and stay active.
                  </p>
                  <p className="body--m">
                    This kind of chronic pain affects 1 in 4 adults in the UK.
                    <span className="sup">¹</span> That’s why we’re launching
                    the Own Pain campaign. By offering support and resources, we
                    want to help people find new ways to own their pain and live
                    happier, healthier lives.
                  </p>
                  <p className="body--m">
                    You can help by sharing your experience. Just film a quick
                    video explaining how pain affects your life, and how you’ve
                    found ways to manage or improve it. We’ve included some
                    ideas for what to say below.
                  </p>
                </div>
                <div className="col col-1 col-empty"></div>
                <div className="col col-3">
                  <div className="references">
                    <h4 className="heading--xs sans bold">References</h4>
                    <ol>
                      <li className="body--s sans" id="footnote-1">
                        NHS England,{" "}
                        <a
                          href="https://www.england.nhs.uk/ourwork/clinical-policy/ltc/our-work-on-long-term-conditions/musculoskeleta"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Musculoskeletal Conditions
                        </a>
                        . Retrieved 6 March 2020.{" "}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Submissions
