import React from "react"
import { graphql } from "gatsby"

const PrivacyPolicy = ({ data }) => {
  return (
    <div className="page-privacy page">
      <div className="wrapper">
        <div className="page_content">
          <div className="row centered">
            <div className="col col-10">
              <h1>{data.wpPage.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query PrivacyQuery {
    wpPage(slug: { eq: "privacy-policy" }) {
      id
      title
      content
    }
  }
`
