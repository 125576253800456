import React, { Component } from "react"
import { navigateTo } from "gatsby"
import { Modal } from "semantic-ui-react"
import GeneralForm from "../forms/form"

class ContactBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      generalModal: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal = () => {
    this.setState({
      generalModal: !this.state.generalModal,
      sent: false,
    })

    navigateTo(this.props.return)
  }

  handleScroll = event => {
    let navPos = 0

    if (this.myDiv) {
      navPos = this.myDiv.getBoundingClientRect().top

      for (var i in this.list.children) {
        let childElement = this.list.children[i]
        let id = childElement.id

        if (id) {
          let top = childElement.getBoundingClientRect().top
          let bottom = childElement.getBoundingClientRect().bottom

          if (top < this.sticky.offsetHeight && bottom > 0) {
            this.setState({
              active: id,
            })
          }
        }
      }

      if (navPos < 0) {
        this.setState({
          sticky: true,
        })
      } else {
        this.setState({
          sticky: false,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true)

    if (this.props.form_status) {
      this.setState({
        modal: true,
        sent: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <>
        <section className="banner_contact">
          <div className="wrapper">
            <h3 className="body--l">
              Looking for specific information but can't find it here?
            </h3>
            <div className="banner_contact__btns">
              <button className="btn green right" onClick={this.toggleModal}>
                <span>Send us a note</span>
              </button>

              <button className="btn green facebook right">
                <span>Ask the community</span>
              </button>
            </div>
          </div>
        </section>
        <Modal
          size="tiny"
          onClose={() => this.toggleModal()}
          onOpen={() => this.toggleModal()}
          open={this.state.generalModal}
        >
          <Modal.Content>
            <GeneralForm
              title="Send us a note"
              description={
                "Have something you'd like to share with us, or a question you'd like to ask? Let us know here or send an email to <a href='mailto:ownpain@oruk.org.uk'>ownpain@oruk.org.uk</a>."
              }
              type="general"
              returnLink={this.props.page}
              sent={this.state.sent}
            />
          </Modal.Content>
        </Modal>
      </>
    )
  }
}

export default ContactBanner
