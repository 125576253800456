import React, { Component } from "react"
import { Link } from "gatsby"
import { Logo } from "../images"
import SearchForm from "./search/searchForm"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
      menu: false,
    }
    this.openMenu = this.openMenu.bind(this)
  }

  openMenu = () => {
    this.setState({
      menu: !this.state.menu,
    })
  }

  componentDidMount() {
    this.setState({
      active: "active",
    })
  }

  render() {
    const active = this.props.active

    return (
      <header className={this.state.menu ? "header menu-open" : "header"}>
        <div className="wrapper">
          <div className="header__inner">
            <Link
              className={
                this.state.active
                  ? "header__logo " + this.state.active
                  : "header__logo"
              }
              onMouseEnter={() => this.setState({ active: "hovered" })}
              to="/"
            >
              <img src={Logo} alt="Own Pain logo" />
              <div className="header__logo__line"></div>
            </Link>
            <button className="header__menu-icon" onClick={this.openMenu}>
              <div></div>
              <span className="sans">{this.state.menu ? "Close" : "Menu"}</span>
            </button>
            <div className="header__nav">
              <SearchForm position="header" />
              <nav className={this.state.menu ? "open" : ""}>
                <ul>
                  <li>
                    <Link to="/" activeClassName="active">
                      Home
                    </Link>
                  </li>
                  <li className="parent">
                    <p>Support</p>
                    {this.state.menu && (
                      <ul>
                        <li>
                          <Link
                            to="/support/talking-to-your-gp"
                            activeClassName="active"
                          >
                            Talking&nbsp;to&nbsp;your&nbsp;GP
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/support/medicines"
                            activeClassName="active"
                          >
                            Medicines
                          </Link>
                        </li>
                      </ul>
                    )}

                    <div className="support_dropdown">
                      <div className="support_dropdown__inner">
                        <div>
                          <Link
                            to="/support/talking-to-your-gp"
                            className={
                              active === "talking-to-your-gp" ? "active" : ""
                            }
                          >
                            <span className="illustration_nav_gp"></span>
                            Talking&nbsp;to&nbsp;your&nbsp;GP
                          </Link>
                        </div>
                        <div>
                          <Link
                            to="/support/medicines"
                            className={active === "medicines" ? "active" : ""}
                          >
                            <span className="illustration_nav_medicines"></span>
                            Medicines
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="/own-stories" activeClassName="active">
                      Own stories
                    </Link>
                  </li>
                  <li>
                    <Link to="/glossary" activeClassName="active">
                      Glossary
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-the-campaign" activeClassName="active">
                      About the campaign
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
