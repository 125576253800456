import React, { Component } from "react"
import { Radio, Modal } from "semantic-ui-react"
import { Link, navigateTo } from "gatsby"
import GeneralForm from "../forms/form"

class PainBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
      submitted: false,
      modal: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    })
    navigateTo("/")
  }

  handleChange = (e, data) => {
    let message = ""
    let color = ""

    switch (data.value) {
      case "Optimistic":
        message =
          "Great! Do you have a story you’d like to share to encourage others?"
        color = "green_light"
        break
      case "Determined":
        message =
          "Great focus! Are there any questions about your pain or treatment methods that we can answer?"
        color = "green_light"
        break
      case "Frustrated":
        message = "Sorry to hear that. We’d love to help if we can."
        color = "red_light"
        break
      case "Sad":
        message =
          "We hear you. We believe everyone with pain can improve their quality of life. That means you."
        color = "blue_light"
        break
      case "Overwhelmed":
        message =
          "We understand. It can be a lot to deal with. Let us help you find your path to improvement, one step at a time."
        color = "red_light"
        break
      default:
      // code block
    }

    this.setState({
      pain: data.value,
      message: message,
      color: color,
    })
  }

  submitForm = e => {
    e.preventDefault()
    this.setState({
      submitted: true,
    })
  }

  componentDidMount() {
    if (this.props.form_status) {
      this.setState({
        modal: true,
        sent: true,
      })
    }
  }

  render() {
    return (
      <>
        <div
          className={
            "banner pain " + (this.state.submitted ? "banner_submitted" : "")
          }
        >
          <div className="illustration_oldman_sitting illustration_01"></div>
          <div className="illustration_gardening illustration_02"></div>
          {!this.state.submitted ? (
            <>
              <form className="pain_form" onSubmit={this.submitForm}>
                <h2 className="body--xl">
                  How are you feeling about managing your pain?
                </h2>
                <div className="form-radio form-radios">
                  <Radio
                    label="Optimistic"
                    name="pain"
                    value="Optimistic"
                    checked={this.state.pain === "Optimistic"}
                    onChange={this.handleChange}
                  />
                  <Radio
                    label="Determined"
                    name="pain"
                    value="Determined"
                    checked={this.state.pain === "Determined"}
                    onChange={this.handleChange}
                  />
                  <Radio
                    label="Frustrated"
                    name="pain"
                    value="Frustrated"
                    checked={this.state.pain === "Frustrated"}
                    onChange={this.handleChange}
                  />
                  <Radio
                    label="Sad"
                    name="pain"
                    value="Sad"
                    checked={this.state.pain === "Sad"}
                    onChange={this.handleChange}
                  />
                  <Radio
                    label="Overwhelmed"
                    name="pain"
                    value="Overwhelmed"
                    checked={this.state.pain === "Overwhelmed"}
                    onChange={this.handleChange}
                  />
                </div>
                <button className="btn green large">
                  <span>Next</span>
                </button>
              </form>
            </>
          ) : (
            <div className="pain_result">
              <div className={"lozenge " + this.state.color}>
                {this.state.pain}
              </div>
              <h2 className="body--xl">{this.state.message}</h2>
              {this.state.pain === "Optimistic" && (
                <div className="pain_result__btns">
                  <Link className="btn yellow" to={"/submissions"}>
                    <span>Tell us your own story</span>
                  </Link>
                  <a
                    href="https://www.facebook.com/ORUKcharity"
                    target="_blank"
                    className="btn facebook green"
                  >
                    <span>Connect with the community</span>
                  </a>
                </div>
              )}
              {this.state.pain === "Determined" && (
                <div className="pain_result__btns">
                  <Link className="btn yellow" to={"/glossary"}>
                    <span>Browse the glossary</span>
                  </Link>
                  <Link
                    className="btn yellow"
                    to={"/support/talking-to-your-gp"}
                  >
                    <span>Get support with your GP</span>
                  </Link>
                  <button
                    onClick={this.toggleModal}
                    className="btn right green"
                  >
                    <span>Send us a note</span>
                  </button>
                </div>
              )}
              {this.state.pain === "Frustrated" && (
                <div className="pain_result__btns">
                  <Link
                    className="btn yellow"
                    to={"/support/talking-to-your-gp"}
                  >
                    <span>Get support with your GP</span>
                  </Link>

                  <button
                    onClick={this.toggleModal}
                    className="btn right green"
                  >
                    <span>Send us a note</span>
                  </button>
                  <a
                    href="https://facebook.com/oruk"
                    className="btn facebook green"
                  >
                    <span>Connect with the community</span>
                  </a>
                </div>
              )}
              {this.state.pain === "Sad" && (
                <div className="pain_result__btns">
                  <Link className="btn yellow" to={"/own-stories"}>
                    <span>Learn about our own stories</span>
                  </Link>
                  <Link
                    className="btn yellow"
                    to={"/support/talking-to-your-gp"}
                  >
                    <span>Get support with your GP</span>
                  </Link>
                  <button
                    onClick={this.toggleModal}
                    className="btn right green"
                  >
                    <span>Send us a note</span>
                  </button>
                </div>
              )}
              {this.state.pain === "Overwhelmed" && (
                <div className="pain_result__btns">
                  <Link className="btn yellow" to={"/own-stories"}>
                    <span>Learn about our own stories</span>
                  </Link>
                  <Link
                    className="btn yellow"
                    to={"/support/talking-to-your-gp"}
                  >
                    <span>Get support with your GP</span>
                  </Link>
                  <a
                    href="https://facebook.com/oruk"
                    className="btn facebook green"
                  >
                    <span>Connect with the community</span>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          size="tiny"
          onClose={() => this.toggleModal()}
          onOpen={() => this.toggleModal()}
          open={this.state.modal}
        >
          <Modal.Content>
            <GeneralForm
              title="Send us a note"
              description={
                "Have something you'd like to share with us, or a question you'd like to ask? Let us know here or send an email to <a href='mailto:ownpain@oruk.org.uk'>ownpain@oruk.org.uk</a>."
              }
              type="general"
              returnLink=""
              sent={this.state.sent}
            />
          </Modal.Content>
        </Modal>
      </>
    )
  }
}

export default PainBanner
