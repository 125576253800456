import React, { Component } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import GlossarySearch from "./glossarySearch"
import GeneralForm from "../forms/form"
import { searchTerm } from "../../functions/searchReplace"
import { Modal } from "semantic-ui-react"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class GlossaryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      modal: false,
    }
    this.selectLetter = this.selectLetter.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  wordChange = word => {
    if (word) {
      return word.replace(/\s+/g, "").toLowerCase().split("(")[0].split("<")[0]
    }
  }

  selectLetter = e => {
    scrollTo("#" + e.target.value)
    this.setState({ active: e.target.value })
  }

  handleScroll = event => {
    let navPos = 0

    if (this.myDiv) {
      navPos = this.myDiv.getBoundingClientRect().top

      for (var i in this.list.children) {
        let childElement = this.list.children[i]
        let id = childElement.id

        if (id) {
          let top = childElement.getBoundingClientRect().top
          let bottom = childElement.getBoundingClientRect().bottom

          if (top < this.sticky.offsetHeight && bottom > 0) {
            this.setState({
              active: id,
            })
          }
        }
      }

      if (navPos < 0) {
        this.setState({
          sticky: true,
        })
      } else {
        this.setState({
          sticky: false,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true)

    if (this.props.form_status) {
      this.setState({
        modal: true,
        sent: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <section className="glossary_list">
        <div className="wrapper">
          <div
            className={
              this.state.sticky ? "glossary_search sticky" : "glossary_search"
            }
            id="myDiv"
            ref={el => {
              this.myDiv = el
            }}
          >
            <div className="row">
              <div className="col col-9 glossary_search--inner">
                <GlossarySearch data={this.props.terms} />
                <div className="glossary_search--scroll">
                  {this.props.terms.map((data, index) => {
                    return data.terms[0].title === "" ? (
                      <span key={index}>{data.letter}</span>
                    ) : (
                      <button
                        onClick={() => scrollTo("#" + data.letter)}
                        key={index}
                      >
                        {data.letter}
                      </button>
                    )
                  })}
                </div>
              </div>
              <Modal
                size="tiny"
                onClose={() => this.toggleModal()}
                onOpen={() => this.toggleModal()}
                open={this.state.modal}
                trigger={
                  <div className="col col-3 cta_question glossary_search--contact">
                    <h3 className="heading--m">
                      Can’t find a term?  <br />
                      Let us know.
                    </h3>
                    <span className="btn_small green">→</span>
                  </div>
                }
              >
                <Modal.Content>
                  <GeneralForm
                    title="submit a term"
                    description="Are we missing a term you’re looking for? Let us know so we can
                    add it."
                    type="term"
                    returnLink="/glossary"
                    sent={this.state.sent}
                  />
                </Modal.Content>
              </Modal>
            </div>
            <div
              className="glossary_sticky"
              ref={el => {
                this.sticky = el
              }}
              id="glossary_sticky"
            >
              <div className="wrapper">
                <div className="glossary_sticky__inner">
                  <div className="glossary_sticky__inner__dropdown">
                    <h3 className="heading--xl">{this.state.active}</h3>
                    {/*eslint-disable */}
                    <select
                      onChange={this.selectLetter}
                      value={this.state.active}
                    >
                      {/*eslint-enable */}
                      {this.props.terms.map((data, index) => {
                        return (
                          data.terms[0].title !== "" && (
                            <option key={index}>{data.letter}</option>
                          )
                        )
                      })}
                    </select>
                  </div>
                  <GlossarySearch data={this.props.terms} />
                </div>
              </div>
            </div>
          </div>
          <ul
            ref={el => {
              this.list = el
            }}
          >
            {this.props.terms.map((data, index) => {
              const words = data.terms

              return (
                data.terms[0].title !== "" && (
                  <li key={`content_item_${index}`} id={data.letter}>
                    <h2 className="glossary_number heading--xl">
                      {data.letter}
                    </h2>
                    {words.map((word, word_index) => {
                      word.id = ""
                      if (word.description) {
                        word.id = word.title.replace(/\s+/g, "-").toLowerCase()
                        word.newTitle = word.title

                        if (this.props.searchTerm) {
                          word.description = searchTerm(
                            this.props.searchTerm,
                            word.description
                          )

                          word.newTitle = searchTerm(
                            this.props.searchTerm,
                            word.title
                          )
                        }

                        return (
                          <div
                            className="glossary_word"
                            key={word_index}
                            id={"word_" + word.id}
                            ref={el => {
                              this[this.wordChange(word.newTitle)] = el
                            }}
                          >
                            <div className="row">
                              <div className="col col-3">
                                <h3
                                  className="body--m bold sans"
                                  dangerouslySetInnerHTML={{
                                    __html: word.newTitle,
                                  }}
                                ></h3>
                              </div>
                              <div className="col col-6">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: word.description,
                                  }}
                                />
                              </div>
                              <div className="col col-1 col-empty"></div>
                              <div className="col col-2">
                                <div
                                  className="glossary_word__reference"
                                  dangerouslySetInnerHTML={{
                                    __html: word.reference,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </li>
                )
              )
            })}
          </ul>
        </div>
      </section>
    )
  }
}

export default GlossaryList
