import React from "react"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"

const Layout = props => {
  return (
    <div className={"page-" + props.page}>
      <SEO title={props.seo.title} description={props.seo.description} />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout
