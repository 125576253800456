import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import Rellax from "rellax"
import Layout from "../components/layout"
import SearchForm from "../components/search/searchForm"
import PainBanner from "../components/banners/pain"
import StoryCard from "../components/story"
import { searchTerm } from "../functions/searchReplace"
import { useQueryParam, StringParam } from "use-query-params"

const Home = ({ data, location }) => {
  const [form_status] = useQueryParam("submitted", StringParam)
  const title = "Home"
  const content = data.wpPage.content
  const posts = data.allWpStory.edges
  const searchTerms = data.wpPage.suggested_search.searchTerms

  useEffect(() => {
    new Rellax(".img_02", {
      speed: 4,
      center: false,
    })
  })

  return (
    <Layout seo={{ title: title }} page="home">
      <section className="home_intro">
        <div className="wrapper">
          <div className="row">
            <div className="col col-6 col-text">
              <div
                className="home_intro__text"
                dangerouslySetInnerHTML={{
                  __html: location.state
                    ? searchTerm(location.state.query, content)
                    : content,
                }}
              />
              <SearchForm placeholder="Search for support" />
              <div className="home_search-terms">
                <h4 className="heading--xs sans bold">Common searches</h4>
                <ul>
                  {searchTerms.map(({ searchTerm }, key) => {
                    return (
                      <li key={key}>
                        <Link to={"/search?term=" + searchTerm}>
                          {searchTerm}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="col col-1 col-empty"></div>
            <div className="col col-5 col-images">
              <div className="home_intro__images">
                <div className="img_01">
                  <Img
                    fluid={data.ImageGardening.childImageSharp.fluid}
                    alt="gardening"
                  />
                </div>
                <div className="img_02">
                  <Img
                    fluid={data.ImageGirl.childImageSharp.fluid}
                    alt="girl"
                  />
                </div>
              </div>
            </div>
          </div>
          <PainBanner form_status={form_status ? form_status : false} />
        </div>
      </section>
      <section className="home_stories stories">
        <div className="arm-right">
          <Img
            fixed={data.ImageArmRight.childImageSharp.fixed}
            alt="arm right"
          />
        </div>

        <div className="wrapper">
          <div className="row intro">
            <div className="col col-1 col-empty"></div>
            <div className="col col-6">
              <div className="home_stories__title">
                <h2 className="heading--xl bold">Own stories </h2>
                <Link className="lozenge_small yellow" to="/own-stories">
                  View&nbsp;all&nbsp;→
                </Link>
              </div>
              <p className="body--l">
                Written accounts and video diaries from our community answering
                the crucial question: How do you own your pain?
              </p>
            </div>
          </div>
          <div className="row centered">
            <div className="col col-10 stories">
              {posts.slice(0, 2).map(({ node }, key) => {
                return (
                  <StoryCard
                    slug={node.slug}
                    image_url={node.stories.photo.sourceUrl}
                    title={node.title}
                    description={node.stories.description}
                    key={key}
                    id={key}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className="arm-left">
          <Img fixed={data.ImageArmLeft.childImageSharp.fixed} alt="arm left" />
        </div>
      </section>
      <section className="home_about">
        <div className="wrapper">
          <div className="row">
            <div className="col col-4">
              <h3 className="heading--l bold">About Orthopaedic Research UK</h3>
              <p className="body--m">
                At Orthopaedic Research UK, we know how tough it can be living
                with bone, joint and muscle conditions. The pain can stop you
                doing things you love, and make it harder to work, see friends
                and stay active. We're here to help.{" "}
              </p>
              <AnchorLink
                className="btn green right"
                to="/about-the-campaign#aboutoruk"
                title="About ORUK"
              >
                <span>Learn more about ORUK</span>
              </AnchorLink>
            </div>
            <div className="col col-2 col-empty"></div>
            <div className="col col-6">
              <Img
                fluid={data.ImageAbout.childImageSharp.fluid}
                alt="about banner"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageHomeQuery = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      id
      title
      content
      suggested_search {
        searchTerms {
          searchTerm
        }
      }
    }
    allWpStory {
      edges {
        node {
          title
          slug
          stories {
            description
            story
            photo {
              sourceUrl
              altText
            }
          }
        }
      }
    }
    ImageGardening: file(relativePath: { eq: "home/gardening-pain.png" }) {
      childImageSharp {
        fluid(maxWidth: 551, maxHeight: 556) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImageGirl: file(relativePath: { eq: "home/girl-pain.png" }) {
      childImageSharp {
        fluid(maxWidth: 436, maxHeight: 389) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImageArmLeft: file(relativePath: { eq: "home/arm-left.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ImageArmRight: file(relativePath: { eq: "home/arm-right.png" }) {
      childImageSharp {
        fixed(width: 395) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ImageAbout: file(relativePath: { eq: "home/about.png" }) {
      childImageSharp {
        fluid(maxWidth: 664, maxHeight: 368) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Home
