// site wide
export const Logo = require("./own_pain.svg")
export const LogoFooter = require("./logo--footer.png")

// home page
export const ArmLeft = require("./home/arm-left.png")
export const ArmRight = require("./home/arm-right.png")
export const Gardening = require("./home/gardening-pain.png")
export const Girl = require("./home/girl-pain.png")
export const AboutBanner = require("./home/about.png")

// holding site
export const Holding01 = require("./holding/image01.png")
export const Holding02 = require("./holding/image02.png")
export const Holding03 = require("./holding/image03.png")
export const Holding04 = require("./holding/image04.png")
export const Holding05 = require("./holding/image05.png")
export const Holding06 = require("./holding/image06.png")
export const Holding07 = require("./holding/image07.png")
export const Holding08 = require("./holding/image08.png")
export const ORUK_logo = require("./oruk-logo.png")
export const Mobile = require("./holding/mobile.png")

// about page
export const About01 = require("./about/about-01.png")
export const About02 = require("./about/about-02.png")
export const AboutHeader = require("./about/header-illustration_about.svg")

// medicines
export const MedicinesHeader = require("./medicines/header-illustration_medicines.svg")

// gp
export const GPHeader = require("./gp/header-illustration_gp.svg")

// glossary
export const GlossaryHeader = require("./glossary/header-illustration_glossary.svg")
