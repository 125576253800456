import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

const SingleStory = ({ data, pageContext, location }) => {
  const postInfo = data.wpStory
  const { description, story, photo } = postInfo.stories

  return (
    <Layout seo={{ title: postInfo.title }} page="own-story">
      <section className="page_header support_header">
        <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span>/</span>
            <Link to="/own-stories">Own Stories</Link>
            <span>/</span>
            <p>{postInfo.title}</p>
          </div>
        </div>
      </section>
      <section className="single-story">
        <div className="wrapper">
          <div className="row centered">
            <div className="col col-6">
              <img src={photo.sourceUrl} />
              <h1 className="heading--xl">{postInfo.title}'s Story</h1>
              <p className="body--l">{description}</p>
              <div dangerouslySetInnerHTML={{ __html: story }} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SingleStory

export const postQuery = graphql`
  query($slug: String!) {
    wpStory(slug: { eq: $slug }) {
      title
      stories {
        description
        story
        photo {
          sourceUrl
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
