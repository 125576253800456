import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

import { GlossaryHeader } from "../images"
import GlossaryList from "../components/glossary/glossaryList"
import { searchTerm } from "../functions/searchReplace"
import { useQueryParam, StringParam } from "use-query-params"

const Glossary = ({ location, data }) => {
  const [form_status] = useQueryParam("submitted", StringParam)
  const glossaryTerms = data.wpPage.pageGlossary.glossaryTerms
  const title = "Glossary"
  let description = data.wpPage.content

  return (
    <Layout seo={{ title: title }} page="glossary ">
      <section className="page_header glossary_header">
        <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span>/</span>
            <p>{title}</p>
          </div>
          <div className="row">
            <div className="col col-6">
              <div
                className="page_intro"
                dangerouslySetInnerHTML={{
                  __html: location.state
                    ? searchTerm(location.state.query, description)
                    : description,
                }}
              />
              <div className="glossary_info">
                <p className="body--xs sans">
                  Medically Reviewed by:{" "}
                  {data.wpPage.pageGlossary.reviewer
                    ? data.wpPage.pageGlossary.reviewer
                    : ""}
                </p>
                <p className="body--xs sans">
                  Last updated:{" "}
                  {data.wpPage.pageGlossary.updatedDate
                    ? data.wpPage.pageGlossary.updatedDate
                    : ""}
                </p>
              </div>
            </div>

            <div className="col col-6">
              <img src={GlossaryHeader} alt="" />
            </div>
          </div>
        </div>
      </section>
      <GlossaryList
        terms={glossaryTerms}
        searchTerm={location.state ? location.state.query : false}
        form_status={form_status ? form_status : false}
      />
    </Layout>
  )
}

export default Glossary

export const query = graphql`
  query GlossaryQuery {
    wpPage(slug: { eq: "glossary" }) {
      id
      title
      content
      pageGlossary {
        glossaryTerms {
          letter
          terms {
            title
            description
            reference
          }
        }
        updatedDate
        reviewer
      }
    }
  }
`
