import React from "react"
import Layout from "../../components/layout"
import Sections from "../../components/sections"
import { MedicinesHeader } from "../../images"
import { searchTerm } from "../../functions/searchReplace"
import { Link, graphql } from "gatsby"

const Medicines = ({ data, location }) => {
  const title = data.wpPage.title
  const content = data.wpPage.content
  const sections = data.wpPage.sections.section

  return (
    <Layout seo={{ title: title }} page="medicines">
      <section className="page_header support_header">
        <div className="wrapper">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span>/</span>
            <p>Support</p>
            <span>/</span>
            <p>{title}</p>
          </div>
          <div className="row">
            <div className="col col-6">
              <div
                className="page_intro"
                dangerouslySetInnerHTML={{
                  __html: location.state
                    ? searchTerm(location.state.query, content)
                    : content,
                }}
              />
            </div>
            <div className="col col-1 col-empty"></div>
            <div className="col col-5 col-image">
              <img src={MedicinesHeader} alt="page header" />
            </div>
          </div>
        </div>
      </section>
      <Sections
        color="darkgreen"
        page="medicines"
        sections={sections}
        location={location}
      />
    </Layout>
  )
}

export default Medicines

export const query = graphql`
  query MedicinesQuery {
    wpPage(slug: { eq: "medicines" }) {
      id
      title
      content
      sections {
        section {
          label
          text
        }
      }
    }
  }
`
